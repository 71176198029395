import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import '../css/AuthForms.css';
import logo from '../assets/htmlcsstoimg_logo.png';

function LoginForm() {
  const navigate = useNavigate();
  const { login, user } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await login(formData, navigate);
      navigate('/');
    } catch (error) {
      // Handle login error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="top-container">
      <div className="auth-form-container">
        <img src={logo} alt="htmlcsstoimage" style={{ maxWidth: '100px', maxHeight: '750px' }} />
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        <p>
          New User? <Link to="/register">Register</Link>
        </p>
        <p>
          Email not Verified? <Link to="/verify">Verify</Link>
        </p>
        <p>
          <Link to="/request-reset-password">Forgot Password?</Link>
        </p>
      </div>
    </div>
  );
}

export default LoginForm;
