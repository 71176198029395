import { useAuth } from '../auth/auth';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import '../css/AuthForms.css'; // Import your existing styles
import logo from '../assets/htmlcsstoimg_logo.png';

function ResetPasswordWithToken() {
  const { token } = useParams(); // Get the token from the URL
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState('');
  const { resetPassword, user } = useAuth();

  useEffect(() => {
    // Check if the user is already logged in
    if (user) {
      navigate('/'); // Redirect to the homepage
    }
  }, [user, navigate]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    }


    try {
      await resetPassword(token, password, navigate);
      setMessage('Verification email sent. Check your inbox.');
      setError('');
    } catch (error) {
      setError('Error sending verification email.');
      setMessage('');
    }

    try {
      const response = await axios.post('https://api.htmlcsstoimg.com/api/v1/segmentation/reset-password/', {
        token,
        password,
      });

      if (response.status === 200) {
        setSuccess('Password reset successfully.');
        setError('');
      } else {
        setError('Password reset failed.');
        setSuccess('');
      }
    } catch (error) {
      setError('Error resetting password.');
      setSuccess('');
    }
  };

  return (
    <div className="top-container">
      <div className="auth-form-container">
      <img src={logo} alt="htmlcsstoimage" style={{ maxWidth: '100px', maxHeight: '750px' }}/>
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="password">New Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
          <button type="submit">Reset Password</button>
        </form>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
      </div>
    </div>
  );
}

export default ResetPasswordWithToken;
