import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../auth/auth'; // Import useAuth from auth.js

function PrivateRoute({ path, ...props }) {
  const { user } = useAuth(); // Get user from useAuth

  if (!user) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

export default PrivateRoute;
