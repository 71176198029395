import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../css/TitleBar.css";
import LogoutButton from "../auth/Logout";
import { useAuth } from '../auth/auth'; // Import useAuth from auth.js
import logo from '../assets/htmlcsstoimg_logo.png';

function TitleBar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { user } = useAuth(); // Get user from useAuth

  const handleDropdownToggle = () => {
    setShowDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="title-bar">
      <Link to="/" className="logo-placeholder"><img src={logo} alt="htmlcsstoimage" style={{ maxWidth: '40px', maxHeight: '40px' }}/></Link>
      <div className="actions">
        {user ? (
          <>
            {/* <Link to="/create-template" className="action-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus"
                style={{ width: "16px", height: "16px", marginRight: "5px" }}
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              Create New Template
            </Link> */}
            <div className="dropdown-container" ref={dropdownRef}>
              <button className={`action-button dropdown-button ${showDropdown ? "active" : ""}`} onClick={handleDropdownToggle}>
                {user.name} {/* Display user's name */}
              </button>
              {showDropdown && (
                <div className="dropdown-content">
                  <p className="dropdown-username">{user.name}</p> {/* Display username */}
                  <LogoutButton />
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Link to="/login" className="action-button">
              Log In
            </Link>
            <Link to="/register" className="action-button">
              Register
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default TitleBar;
