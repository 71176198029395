import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import '../css/AuthForms.css'; // Import your existing styles
import logo from '../assets/htmlcsstoimg_logo.png';

function ForgotPasswordForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { requestResetPassword, user } = useAuth();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    // Check if the user is already logged in
    if (user) {
      navigate('/'); // Redirect to the homepage
    }
  }, [user, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await requestResetPassword(email);
      navigate('/reset-password');
    } catch (error) {
      // Handle login error
    }
  };

  return (
    <div className="top-container">
    <div className="auth-form-container">
    <img src={logo} alt="htmlcsstoimage" style={{ maxWidth: '100px', maxHeight: '750px' }}/>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}
      <p>
        Remember your password? <Link to="/login">Log in</Link>
      </p>
    </div>
    </div>
  );
}

export default ForgotPasswordForm;
