import React from 'react';
import { Outlet } from 'react-router-dom';
import '../css/AuthForms.css';

function AuthPage() {
  return (
    <div className="auth-page-container">
      <Outlet />
    </div>
  );
}

export default AuthPage;
