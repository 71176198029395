import React, { useState, useEffect } from 'react';
import '../htcicss/dashboard.css'; // Import your CSS file
import { authAxios } from "../auth/auth";

import zapier from '../assets/zapier.png'
import make from '../assets/make.png'
import pabbly from '../assets/pabbly.png'




function ApiKeyComponent() {
  const [apiKey, setApiKey] = useState('');
  const [apiInfo, setApiInfo] = useState({});

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    // Fetch account info as before
    try {
      const response = await authAxios.get('https://api.htmlcsstoimg.com/api/v1/auth/apiInfo');
      const data = response.data;
      // console.log(data)
      setApiInfo(data);
      setApiKey(data.api_key)
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  };

  const generateApiKey = async () => {
    // Generate API key as before
    try {
      const response = await authAxios.get('https://api.htmlcsstoimg.com/api/v1/auth/generate');
      const data = response.data;
      // console.log(data)
      setApiInfo(data);
      setApiKey(data.api_key)
    } catch (error) {
      console.error('Error fetching keys:', error);
    }
  };

  const openAPIDocsInNewTab = () => {
    // Replace 'https://www.example.com' with the URL of the website you want to open
    const websiteUrl = 'https://docs.htmlcsstoimg.com';

    // Open the website in a new tab
    window.open(websiteUrl, '_blank');
  };

  const openPricingPage = () => {
    // Replace 'https://www.example.com' with the URL of the website you want to open
    const websiteUrl = 'https://htmlcsstoimg.com/pricing/';

    // Open the website in a new tab
    window.open(websiteUrl, '_blank');
  };

  const openIntegrationLink = (link) => {
    window.open(link, '_blank'); // Open link in a new tab
  };

  return (
    <div className="parent-container">
      <div className="api-grid">
        <div className="left-column">
          <div className="api-key-card">
            <div className="card-content">
              <h3>API Key</h3>
              {apiKey ? (
                <div>
                  <p className="api-key">{apiKey}</p>
                  <p className="generated-date">
                    Generated Date: {apiInfo.api_updated_at || 'N/A'}
                  </p>
                  <button onClick={generateApiKey} className="generate-button">
                    Regenerate API Key
                  </button>
                </div>
              ) : (
                <button onClick={generateApiKey} className="generate-button">
                  Generate API Key
                </button>
              )}
              <button onClick={openAPIDocsInNewTab} className="api-docs">
                API Docs
              </button>
            </div>
          </div>
          <div className="api-info-card">
            <div className="card-content">
              <h3>API Hit Count</h3>
              <p>{apiInfo.api_hit || 0}</p>
            </div>
          </div>
          <div className="api-info-card">
            <div className="card-content">
              <h3>Remaining Count</h3>
              <p>{apiInfo.allowed_hit - apiInfo.api_hit || 0}</p>
              <button onClick={openPricingPage} className="upgrade">
                Upgrade
              </button>
            </div>
          </div>
          <div className="subscription-card">
            <div className="card-content">
              <h3>Subscription Details</h3>
              <p>End Date: {apiInfo.plan_expires_at || 'N/A'}</p>
              <p>Plan Type: {apiInfo.plan_type || 'N/A'}</p>
              <button onClick={openPricingPage} className="upgrade">
                Upgrade
              </button>
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="welcome-card">
            <div className="card-content-right">
              <h3>Welcome to Our Service</h3>
              <p>Learn how to use our API with this tutorial</p>
              <div className="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/qn3fQu5AHxw"
                  title="YouTube video tutorial"
                  allowFullScreen
                ></iframe>
              </div>
              {/* <p className='upgrade'>To upgrade your plan to buy more credits, please write to support@htmlcsstoimg.com</p> */}



            </div>
          </div>



          <h3>Native Integrations</h3>
          <p>Integrate within minutes using native integrations with No-Code Tools you love</p>


          <div className="integration-row">
            <div className="integration-item">
              <img width="80px" src={zapier} alt="Zapier Integration" />
              <button className="no-fill-button" onClick={() => openIntegrationLink('https://zapier.com/apps/html-to-image/integrations')}>View Integration</button>
            </div>

            <div className="integration-item">
              <img width="80px" src={make} alt="Make Integration" />
              <button className="no-fill-button" onClick={() => openIntegrationLink('https://www.make.com/en/integrations/html-to-img-api')}>View Integration</button>
            </div>

            <div className="integration-item">
              <img width="80px" src={pabbly} alt="Pabbly Connect Integration" />
              <button className="no-fill-button" onClick={() => openIntegrationLink('https://www.pabbly.com/connect/integrations/html-to-image/')}>View Integration</button>
            </div>
          </div>


        </div>



      </div>
    </div>
  );
}

export default ApiKeyComponent;
