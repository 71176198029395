// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LogoutButton.css */

.logout-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .logout-button:hover {
    background-color: #4848f4;
  }
  
  .logout-button:focus {
    outline: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/LogoutButton.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,2DAA2D;EAC7D;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf","sourcesContent":["/* LogoutButton.css */\n\n.logout-button {\n    background-color: #007BFF;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    padding: 8px 15px;\n    font-size: 14px;\n    cursor: pointer;\n    transition: background-color 0.3s ease, transform 0.2s ease;\n  }\n  \n  .logout-button:hover {\n    background-color: #4848f4;\n  }\n  \n  .logout-button:focus {\n    outline: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
