import qs from 'qs';
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(() => {
    // Attempt to get user data from local storage
    const storedUser = localStorage.getItem('user_hcti');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    // Attempt to get JWT token from local storage
    const token = localStorage.getItem('token_hcti');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  const login = async (userData, navigate) => {
    try {
      // Convert FormData to JSON
      // userData = qs.parse(userData);
      const response = await axios.post('https://api.htmlcsstoimg.com/api/v1/auth/login/', userData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      if (response.status === 200) {
        const { user, access_token } = response.data;
        setUser(user);
        toast.success("Logged in Successfully :)")
        // Store the user data and token in local storage
        localStorage.setItem('user_hcti', JSON.stringify(user));
        localStorage.setItem('token_hcti', access_token);
        // Set token in axios headers
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      } else {

        console.log("error")
        toast.error(response.data)

        // throw new Error('Login failed');
      }
    } catch (error) {

      const responseData = error.response && error.response.data;
      // const errorMessage = responseData ? responseData.message : 'An error occurred while logging in';

      if (responseData)
        toast.error(error.response.data.detail);
      if (responseData === "Email not Verified")
        navigate('/verify');
      // toast.error("error")
      console.error('Login error:', error.response.data.detail);
      throw error;
    }
  };

  const register = async (userData, navigate) => {
    try {
      // Convert FormData to JSON
      userData = qs.parse(userData);
      const response = await axios.post('https://api.htmlcsstoimg.com/api/v1/auth/register/', userData);
      console.log(response)
      console.log("registered")
      if (response.status === 201) {
        console.log("toast")
        toast.success("Please check your email to verify your email.")
        // navigate('/verify');
      } else {
        // console.log("error")
        // toast.error(response.data)
        throw new Error('Registration failed');
      }
    } catch (error) {
      const responseData = error.response && error.response.data;
      console.log(responseData)
      // const errorMessage = responseData ? responseData.message : 'An error occurred while logging in';

      if (responseData)
        toast.error(error.response.data.detail);
      console.error('Registration error:', error);
      throw error;
    }
  };

  const requestResetPassword = async (email, navigate) => {
    try {
      const response = await axios.post('https://api.htmlcsstoimg.com/api/v1/auth/request-reset-password/', { email });
      if (response.status === 200) {
        navigate('/login');
      } else {
        throw new Error('Password reset request failed');
      }
    } catch (error) {
      console.error('Password reset request error:', error);
      throw error;
    }
  };


  const resetPassword = async (token, password, navigate) => {
    try {
      const response = await axios.post('https://api.htmlcsstoimg.com/api/v1/auth/reset-password/', { token, password });
      if (response.status === 200) {
        toast.success("Please check your email to reset your password.")
        navigate('/login');
      } else {
        throw new Error('Password reset failed');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      throw error;
    }
  };

  const verifyEmail = async (email, navigate) => {
    try {
      const response = await axios.post('https://api.htmlcsstoimg.com/api/v1/auth/request-verify-email/', { email });
      if (response.status === 200) {
        // Password reset successful
        // navigate('/login');
        toast.success("Please check your email to verify your email.")
        navigate('/login');
      } else {
        throw new Error('Email verification failed');
      }
    } catch (error) {
      console.error('Email verification error:', error);
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    // Remove user data and token from local storage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    // Remove token from axios headers
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, login, register, logout, requestResetPassword, verifyEmail, resetPassword }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

// Create an instance of Axios with the default headers
export const authAxios = axios.create();

// Add an interceptor to include the Authorization header in every request
authAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token_hcti');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
