import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth'; // Import useAuth from auth.js
import '../css/AuthForms.css'; // Import your existing styles
import logo from '../assets/htmlcsstoimg_logo.png';

function RegistrationForm() {
  const navigate = useNavigate();
  const { register, user } = useAuth(); // Use the register function from useAuth
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in
    if (user) {
      navigate('/'); // Redirect to the homepage
    }
  }, [user, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await register(formData, navigate); // Call the register function from useAuth
      navigate('/login'); // Redirect to the login page after successful registration
    } catch (error) {
      // Handle registration error
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="top-container">
      <div className="auth-form-container">
        <img src={logo} alt="htmlcsstoimage" style={{ maxWidth: '100px', maxHeight: '750px' }} />
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
          {/* <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          /> */}
          <button type="submit" disabled={loading}>
            {loading ? 'Registering...' : 'Register'}
          </button>
        </form>
        <p>
          Already Registered? <Link to="/login">Go to Login</Link>
        </p>
      </div>
    </div>
  );
}

export default RegistrationForm;
