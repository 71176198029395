import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth';
import '../css/AuthForms.css'; // Import your existing styles
import logo from '../assets/htmlcsstoimg_logo.png';

function VerifyEmailForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { verifyEmail, user } = useAuth();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      await verifyEmail(email, navigate);
      setMessage('Verification email sent. Check your inbox.');
      setError('');
      // navigate('/login');
    } catch (error) {
      setError('Error sending verification email.');
      setMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="top-container">
      <div className="auth-form-container">
        <img src={logo} alt="htmlcsstoimage" style={{ maxWidth: '100px', maxHeight: '750px' }} />
        <h2>Verify Email</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send Verification Email'}
          </button>
        </form>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <p>
          Already verified? <Link to="/login">Log in</Link>
        </p>
      </div>
    </div>
  );
}

export default VerifyEmailForm;
