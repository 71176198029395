import './App.css';
import './css/App.css'
// import React, { Component } from 'react';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { Editor } from 'react-draft-wysiwyg';

import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import TitleBar from './components/TitleBar';
import AuthPage from './components/AuthPage';
import RegisterForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import PrivateRoute from './components/PrivateRoute';
import ForgotPasswordForm from './auth/ForgotPasswordForm';
import VerifyEmailForm from './auth/VerifyEmailForm';
import ResetPasswordWithToken from './auth/ResetPasswordWithToken';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ApiKeyComponent from './htci/dashboard';


// const EditorComponent = () => <Editor />

function App() {
  useEffect(() => {
    // const fetchLabels = async () => {
    //   try {
    //     const response = await authAxios.get('http://45.79.120.38:8092/api/v1/templates/labels_fetch/get');
    //     const labelNames = response.data;
    //     console.log(labelNames)
    //     setLabels(labelNames)
    //   } catch (error) {
    //     console.error('Error fetching labels:', error);
    //   }
    // };

    // fetchLabels();
  }, []);

  // const addNewLabel = (newLabel) => {
  //   // Add logic to create a new label and update the labels list
  //   const updatedLabels = [...labels, newLabel];
  //   setLabels(updatedLabels);
  // };

  return (
    <Router>
      <TitleBar />
      <Routes>
        <Route path="/test/" element={<RedirectToAuth />} />
        <Route path="/*" element={<AuthPage />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="request-reset-password" element={<ForgotPasswordForm />} />
          <Route path="verify" element={<VerifyEmailForm />} />
          <Route path="reset-password" element={<ResetPasswordWithToken />} />
        </Route>
        <Route exact path='/' element={<PrivateRoute />}>
          <Route exact path='/' element={
            <div className="app">
              <ApiKeyComponent />
            </div>
          } />

        </Route>

        <Route path="/hci/dashboard" element={
          <div className="app">
            <ApiKeyComponent />
          </div>
        } />

      </Routes>
      <ToastContainer />
    </Router>
  );
}

function RedirectToAuth() {
  const navigate = useNavigate();

  // Perform redirection to /auth
  navigate('/login');

  // You can also return null or a placeholder component
  return null;
}

export default App;
