import React from 'react';
import { useAuth } from '../auth/auth';
import { useNavigate } from 'react-router-dom';
import '../css/LogoutButton.css'; // Import your custom CSS styles

function LogoutButton() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <button className="logout-button" onClick={handleLogout}>
      Logout
    </button>
  );
}

export default LogoutButton;
